.custom-button {
    background-color: transparent;
    height: 40px;
    width: 125px;
    border-radius: 20px;
    color: var(--secondary-color);
    font-family: var(--font-base);
    font-size: 17px;
    color: black;
    font-weight: 600;
    border: 2px solid var(--primary-color);
    cursor: pointer;
  }


  @media screen and (max-width: 360px) {
    .custom-button {
      width: 100%;
    }
  }