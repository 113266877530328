.app__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    padding: 0 5rem 5rem 5rem;
}

.app__header_left_content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.app__header_left_content p {
    font-family: var(--font-alt);
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--secondary-color);
    text-align: left;
}

.app__header_button {
    background-color: var(--primary-color);
    height: 40px;
    width: 150px;
    border-radius: 20px;
    color: black;
    font-family: var(--font-base);
    font-size: 16px;
    color: white;
    font-weight: 400;
    border: 1px solid #d1d1d1 ;
    cursor: pointer;
    /* box-shadow: 2px 2px var(--secondary-color); */
    /* margin-top: 8px; */
    margin-right: 15px;
}

.app__header_right_content {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__header-title {
    font-family: var(--font-base);
    font-size: 50px;
    margin-bottom: 8px;
}

.app__header-name {
    font-family: var(--font-base);
    margin: 0;
    color: #f8ce31;
    font-size: 70px;
    text-align: left;
}

.app__header-developer {
    font-family: var(--font-base);
    font-size: 50px;
    text-align: left;
    margin-top: 0 ;
    margin-bottom: 0;
}

.app__header_social_media {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

}

.app__header_neumorfismo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    /* background: linear-gradient(145deg, #e6e6e6, #ffffff); */
    background: rgb(248, 247, 247);
    box-shadow:  5px 5px 4px #e0e0e0,
             -5px -5px 4px #ffffff;
}

.app__header_neumorfismo:hover {
    /* color:var(--primary-color); */
    transform: scale(1.3);
}

.app__header_neumorfismo:active {
    /* color:var(--primary-color); */
    transform: scale(1);
}

@media screen and (max-width: 2000px) {

    .app__navbar-logo img {
        width: 210px;
    }
  
  }
  
  @media screen and (max-width: 1000px) {

    /* .app__header {
        
        padding-left: 30px;
        padding-right: 30px;   
    } */

    .app__header-title {
        font-size: 3.5rem;
        /* margin-top: 0px; */
    }
    .app__header-name {
        font-size: 3.0rem;
        text-align: left;
    }
    .app__header-developer {
        font-size: 3.5rem;
    }
    .app__header_left_content p {
        font-size: 1rem;
        text-align: justify;
    }

    .app__header_right_content img {
        margin: 0;
    }
  }
  
  @media screen and (max-width: 750px) {
    
    .app__header {
        flex-direction: column-reverse; 
        padding-left: 15px;
        padding-right: 15px;
        /* margin-top: 3rem;    */
    }

    .app__header-title {
        font-size: 2rem;
        margin-top: 0px;
    }
    .app__header-name {
        font-size: 2.5rem;
        text-align: left;
    }
    .app__header-developer {
        font-size: 2rem;
        text-align: left;
    }
    .app__header_left_content p {
        font-size: 1rem;
        text-align: justify;
    }
    .app__header_right_content img {
        width:330px;
        margin: 0;
    }

    
  }

  @media screen and (max-width: 360px) {
    
    .app__header {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center; 
        padding-bottom: 1rem;
        /* padding-left: 20px;
        padding-right: 20px;   */
        /* width: 100%;  */
    }

    #buttons a {
        width: 100%;
    }

    .app__header-title {
        font-size: 2rem;
        margin-top: 0px;
    }
    .app__header-name {
        font-size: 2.5rem;
        text-align: left;
    }
    .app__header-developer {
        font-size: 2rem;
        text-align: left;
    }
    .app__header_left_content p {
        font-size: 1rem;
        text-align: justify;
    }
    .app__header_right_content img {
        width:200px;
        margin: 0;
    }
    .app__header_right_content {
        margin-left: 0;
        width: 100%;
    }
    .app__header_left_content {
        width: 100%;
    }

    .app__header_button {
        margin-bottom: 5px;
        margin-right: 0px;
        width: 100%;
    }

    #buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app__header_social_media{
        justify-content: center;
        align-items: center;
        width: 100%;
    }
  }