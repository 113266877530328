.app__subheading-title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__subheading-line {
    border-radius: 20px;
    height: 1px;
    background-color: var(--primary-color);
    width: 100%;
}

.app__heading-div {
    width: 33px;
    height: 5px;
    background-color: var(--primary-color);
    border-radius: 10px;
}

.app__heading-h1 {
    font-family: var(--font-base);
    font-size: 4rem;
    color: var(--primary-color);
    margin-right: 0;
    margin-left: 0;
    margin-top: 9px;
}

