.card-container {
    width: 180px;
    height: 180px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: #f3f2f2;
    box-shadow: 2px 2px var(--secondary-color);
    border-radius: 15px;
}

.card-container-outerdiv {
    height:230px;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.card-container h3 {
    font-family: var(--font-alt);
    color: black;
    font-weight: 800;
    font-size: 1.2rem;
}

.card-container h3:hover {
    font-family: var(--font-alt);
    color: white;
}

.card-container:hover {
    background-color: #f3bc6b;
    height: 190px;
    margin-bottom: 15px;
    color: white;
}

