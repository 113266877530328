.app__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    /* margin-top: 3rem; */
    /* background-color: #F3f5f5 */
}

.app__footer span {
    font-family: var(--font-base);
    color: #404040;
    font-weight: 700 ;
    font-size: 1.2rem;
}

@media screen and (max-width: 750px) {
  
    .app__footer span {
        font-size: 0.9rem;
    }

  }