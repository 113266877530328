.app__experience {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 0px 30px; */
}

.app__experience-title {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

/* .app__experience-line {
    border-radius: 20px;
    height: 1px;
    background-color: var(--primary-color);
    width: 100%;
} */

.app__experience-div {
    width: 38px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 10px;
    margin-bottom: 5px;
    /* box-shadow: 5px 5px 15px gray; */
}

.app_experience-h1 {
    font-family: var(--font-base);
    color: var(--primary-color);
    font-size: 6rem;
    margin-top: 10px;
    margin-bottom: 0px;
    /* text-shadow: 1px 1px 1px gray; */
}

.app__experience-timeline {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 800px; */
}

#paragraph {
    font-family: var(--font-alt);
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--secondary-color);
    text-align: justify;
}

@media screen and (max-width: 1000px) {
  
    .app__experience {
        /* margin-top: 20px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;
        /* padding: 0px 30px; */
    }

    .app__experience-title {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .app_experience-h1 {
        font-size: 4rem;
    }
    
  }

  @media screen and (max-width: 360px) {
  
    /* .app__experience {
        width: 200px;
    } */

    .app_experience-h1 {
        font-size: 3rem;
    }

    .app__experience-div {
        width: 38px;
        height: 7px;
        
    }

    .app__experience-timeline {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        /* border: 3px solid red; */
    }

    .app__experience-title {
        padding: 0 20px;
    }

    #timeline {
        width: 270px;
        /* border: 3px solid green; */
        padding: 0;
    }

    #paragraph {
        
        font-size: 1rem;
        /* padding: 0 5px; */
    }
    
  }