.app__navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0 5rem;
  position: fixed;
  box-sizing:border-box;
  top: 0px;
  width: calc(100% - 0px);
  height: 80px;
  z-index: 1000;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-base);
  font-size: 35px;
  font-weight: 800;
  cursor: pointer;
  padding: 0px;
}

.logo_movil {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-base);
  font-size: 35px;
  font-weight: 800;
  /* color: black; */
  cursor: pointer;
  padding: 0px;
}

.logo a {
  color: black;
}

.logo_movil a {
  color: black;
}

.app__navbar_links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.app__navbar_links li {
  margin: 1rem;
}

.app__navbar_links a:hover {
  border-bottom: 2px solid #ffad01;
}

.app__navbar_links a {
  color: black;
  font-family: var(--font-alt);
  font-weight: 700;
}

.app__navbar_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.app__navbar_button button {
  background-color: transparent;
  height: 40px;
  width: 120px;
  border-radius: 20px;
  color: black;
  font-family: var(--font-alt);
  font-size: 15px;
  color: black;
  font-weight: 600;
  border: 2px solid #ffad01;
  cursor: pointer;
}

.app__navbar-select {
  font-family: var(--font-base);
  width: 100%;
  /* height: 30px;     */
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid var(--primary-color);
  border-radius: 30px;
  background: #ffffff;
  color: black;     
}

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}   

.select select {
  font-family: 'Arial';
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid var(--primary-color);
  border-radius: 0px;
  background: #ffffff;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.app__navbar_movil_rightside {
  display:flex;
  align-items:center;
  justify-content:center;
  gap: 10px;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: #fff;
  background: #ffffff;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
  border-color: #000000;
}
.select select:disabled ~ .select_arrow {
  border-top-color: #cccccc;
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

.app__navbar-smallscreen_overlay_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.app__navbar-smallscreen_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
}

.app__navbar_smallscreen_avatar {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #404040;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  border: 4px solid #ffad01;
}

.avatar_decoration {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid transparent;
  background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(white, white), 
                    linear-gradient(30deg, #ffad01 56%,  white 55%),
                    linear-gradient(120deg, #ffad01 56%, white 50%),
                    linear-gradient(300deg, #ffad01 56%, white 50%),
                    linear-gradient(210deg, #ffad01 56%, white 50%);
  background-position: center center, left top, right top, left bottom, right bottom;
  background-origin: content-box, border-box, border-box, border-box, border-box;
  background-clip: content-box, border-box, border-box, border-box, border-box;
  transform: rotate(0deg);

  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.app__navbar_smallscreen_avatar h2 {
  font-family: var(--font-base);
  color: white;
  font-size: 2rem;
  /* margin-bottom: 0; */
}

.app__navbar-smallscreen_dev {
  font-family: var(--font-base);
  color: var(--secondary-color);
  font-size: 1rem;
  margin-top: 0;
  font-weight: 500;
}

.app__navbar_smallscreen_info h2 {
  font-family: var(--font-base);
  color: #404040;
  font-size: 2rem;
  /* margin-bottom: 0; */
}

.app__navbar_smallscreen_social{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 10px;
  
}

.app__navbar_smallscreen_labels {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.app__navbar_smallscreen_labels div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  width: 100px;
  height: 100%;
}

.app__navbar_smallscreen_labels h5{
  margin-bottom: 0;
  font-family: var(--font-base);
  font-size: 1.2rem;
}

.app__navbar_smallscreen_labels h6{
  margin-top: 5px;
  font-family: var(--font-base);
  font-weight: 500;
  color: var(--secondary-color);
  font-size: 0.9rem;
}

.app__navbar_smallscreen_contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
}

.app__navbar_smallscreen_button{
  height: 40px;
  width: 180px;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: white;
  font-family: var(--font-base);
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
}

.app__navbar_smallscreen_button:active {
  background-color: #ae8b0c;
  scale: 1.1;

}

.app__navbar_smallscreen_button:focus {
  background-color: var(--primary-color);
  color: white;
}

.app__navbar_smallscreen_button > span {
  margin-left: 3px;
}

.app__navbar_smallscreen_roundedbutton {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  background-color: rgb(225, 225, 225);
}

.app__navbar_smallscreen_menu {
  width: 60%;
  height: 40px;
  margin-top: 50px;
  border-radius: 30px;
  padding: 3px 5px;
  display: flex;
  gap:'1';
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(225, 225, 225); */
  background-color: rgba(200, 200, 200  , 60%);
  backdrop-filter: blur(50px);
  
}


@keyframes rotate{
  from{ transform: rotate(-360deg); }
  to{ transform: rotate(360deg); }
}

@media screen and (max-width: 2000px) {

  .app__navbar-logo img {
      width: 210px;
  }

}

@media screen and (max-width: 1000px) {
  
  .app__navbar{
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100%);
    /* height: 100vh; */
  }
  /* .app__navbar_links {
      display: none;
  } */

  #f {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  #f > span, #f > a {
    font-size: 2rem;
  }

  
  .app__navbar-smallscreen_overlay {
    /* border: 3px solid red;  */
    height: 100vh;
    
  }

  .app__navbar-smallscreen_info {
    height: 100vh;
  }

  /* .app__navbar-select{
      display: none;
  } */

  .logo {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  
  .app__navbar {
      padding: 0.5rem;
      width: calc(100% - 10px);
      /* margin-bottom: 3rem; */
      /* height: 100vh; */
  }

  .logo span, a{
    font-size: 1.7rem;
  }
  .app__navbar-login {
      display: none;
  }

   .app__navbar_links {
      display: none;
  }

  .app__navbar-select{
      display: none;
  }

  .app__navbar-logo img {
      width: 110px;
  }

  .app__navbar-smallscreen {
    display: flex;
    align-items: center;
  }

  .app__navbar-smallscreen_info {
    margin-top: 20px;
  }

  .app__navbar_smallscreen_menu{
    margin-top: 40px;
    gap: 4;
    width: 70%;
    border-radius: 30px;
    padding: 3px 5px;
  }

}


button:hover {
  color: black;
}
