.app__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* padding: 300px 0px;   */
    /* padding: 280px 0px 100px 0px; */
}

.app__contact_title {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 4rem;
    width: 650px;
    color: var(--primary-color);
    margin-bottom: 0;
}

.app__contact p {

    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.3rem;
    font-weight: bold;
    color: #404040;
}

@keyframes reveal {
    from {
        translate: 0 20px;
    }
    to {
        translate: none;
        opacity: 1;
    }
}

.app__contact span {
    border-bottom: 3px solid var(--primary-color);
    cursor: pointer;
    color: var(--primary-color);
}

.active {
    background-color: var(--primary-color);
}

@media screen and (max-width: 1200px) {
  
    .app__contact_title {
        font-size: 4rem;
        width: 100%;
        text-align: center;
    }

    .app__contact{
        height: 300px;
    }

    .app__contact p {
        font-size: 1.3rem;
        /* text-align: center; */
    }

  }

@media screen and (max-width: 750px) {
  
    .app__contact_title {
        font-size: 3rem;
        width: 100%;
        text-align: center;
    }

    .app__contact{
        height: 300px;
    }

    .app__contact p {
        font-size: 1rem;
        /* text-align: center; */
    }

  }


  @media screen and (max-width: 360px) {
  
    .app__contact_title {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .app__contact{
        height: 200px;
    }

    .app__contact p, .app__contact span {
        font-size: 0.8rem;
        text-align: center;
    }


  }