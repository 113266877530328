@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;400;500;800&family=Open+Sans:ital,wght@0,300;0,400;1,400;1,500&display=swap");

.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
}

:root {
  --font-base: "League Spartan";
  --font-alt: "Open Sans";
  --primary-color: #f8ce31;
  --secondary-color: #bcbcbc;

}

#root {
  overflow-x: hidden;
  height: 100%;
  /* padding: 0;
  margin: 0;
  width: 100%; */
}

a {
  text-decoration: none;
}

li {
  color: white;
  list-style: none;
  cursor: pointer;
}

html {
  height: 100%;
}
 body {
  /* box-sizing: border-box; */
  padding: 0;
  margin: 0;
  /* width: 100%; */
  min-height: 100%;
  scroll-behavior: smooth;
}

.section__padding {
  /* padding: 3rem 5rem; */
  padding: 5.5rem 5rem 5rem 5rem;
}

.flex__center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

::-webkit-scrollbar {
  display: none;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


@media screen and (max-width: 650px) {
  
  .section__padding {
    /* padding: 3rem 5rem; */
    padding: 3rem 3rem 3rem 3rem;
  }
  
}

@media screen and (max-width: 360px) {
  
  .section__padding {
    /* padding: 3rem 5rem; */
    padding: 1rem 0rem 1rem 0rem;
  }
  
}
