.app__about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 80px;
    padding-right: 80px;
    /* margin-top: 120px; */
    /* background-color: #f3f2f2; */
}

.app__about-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.app__about-title {
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__about-decorative-div {
    width: 50px;
    height: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* border-top: 3px solid var(--primary-color); */
    background-color: var(--primary-color);
    border-radius: 20px;
}

.app__about-line {
    border-radius: 20px;
    height: 1px;
    background-color: var(--primary-color);
    width: 100%;
    /* flex: 1; */
}

.app__about-h1 {
    font-family: var(--font-base);
    font-size: 4rem;
    color: var(--primary-color);
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0;
    width: 100%;
    flex: 1;
    /* margin-top: 80px; */
}

.app__about-p {
    margin-top: 0;
    font-family: var(--font-alt);
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--secondary-color);
    text-align:justify;
}

.app__about-p span {
    color: var(--primary-color);
    font-weight: 600;
}

@media screen and (max-width: 650px) {
  
    .app__about {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 5rem;
    }

    #decorative-second {
      display: none;
    }

    .app__about-h1 {
        font-size: 3rem;
    }

    .app__about-p {
        font-size: 1.1rem;
    }
    
  }

  @media screen and (max-width: 360px) {
  
    .app__about {
        padding-left: 25px;
        padding-right: 25px;
    }

    #decorative-second {
      display: none;
    }

    .app__about-h1 {
        font-size: 3rem;
    }

    .app__about-p {
        font-size: 1.1rem;
    }
    
  }

