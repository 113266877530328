.app__skill {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 100%; */
    /* border-top: 1px solid var(--secondary-color); */
}

.app__skill-div {
    width: 33px;
    height: 5px;
    background-color: var(--primary-color);
    border-radius: 10px;
}

.app__skill-title {
    font-family: var(--font-base);
    font-size: 4rem;
    color: var(--primary-color);
    margin-top: 10px;
}

.app__skill-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 120px;
    padding-right: 120px;
}

@media screen and (max-width: 1000px) {
  
    .app__skill-cards{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    
  }

  @media screen and (max-width: 750px) {

    .app__skill {
        padding-top: 5rem;
    }
  
    .app__skill-cards{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
       
    }

    .app__skill-title {
        font-family: var(--font-base);
        font-size: 3rem;
        width: 100%;
        color: var(--primary-color);
        
    }
    
  }
