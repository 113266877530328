.app__projects {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 100%; */
    /* margin-top: 50px; */
    /* border-top: 1px solid var(--secondary-color); */
}

.app__projects-div {
    width: 33px;
    height: 5px;
    background-color: var(--primary-color);
    border-radius: 10px;
}

.app__projects-title {
    font-family: var(--font-base);
    font-size: 4rem;
    color: var(--primary-color);
    margin-top: 10px;
}

.app__projects-projectcards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 100vw; */
    margin-top: 50px;
}

@media screen and (max-width: 1200px) {
  
    .app__projects-projectcards {
        display: flex;
        flex-wrap: wrap;
        /* gap: 30px; */
        align-items: center;
        justify-content: center;
        /* width: 100vw; */
        margin-top: 50px;
    }
    
  }

  @media screen and (max-width: 700px) {
        .app__projects {
            padding-top: 5rem;
        }
  }


  @media screen and (max-width: 450px) {
  
    .app__projects-title {
        font-family: var(--font-base);
        font-size: 3rem;
        color: var(--primary-color);
       
    }
    
  }