.app__cardproject {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 340px;
    box-shadow: 2px 2px 7px 2px gray;
    border-radius: 20px;
    padding: 15px 10px;
}

.app__cardproject_headericons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  0px 15px 10px 15px;
    border-bottom: 1px solid var(--secondary-color);

}

.app__cardproject_headericons div {
    display: flex;
    gap: 8px;
}

.iconscard {
    font-size: 3rem;
    color: var(--primary-color);
}

.iconscard_external_link {
    font-size: 1.8rem;
    color: #404040;
    cursor: pointer;
    /* box-shadow: 5px 5px 5px gray; */
}

.app__cardproject_neumorfismo{
    border: none;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    /* background: linear-gradient(145deg, #e6e6e6, #ffffff); */
    background: rgb(248, 247, 247);
    box-shadow:  5px 5px 4px #e0e0e0,
             -5px -5px 4px #ffffff;
}

button:hover {
    background: #ffffff;
}

.app__cardproject h2 {
    font-family: var(--font-base);
    color: black;
    /* margin: 0; */
}

.app__cardproject p {
    font-size: 0.9rem;
    font-family: var(--font-alt);
    font-weight: 500;
    letter-spacing: 1px;
    color: #404040;
    text-align: left;
}

.app__cardproject_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    align-items: flex-start;
}

.app__cardproject_technologies {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 9px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0;
}

.app__cardproject_technologies h5 {
    font-family: var(--font-alt);
    color: var(--secondary-color);
}

.iconscard:hover {
    color:var(--primary-color);
}

.app__cardproject_neumorfismo:hover {
    /* color:var(--primary-color); */
    transform: scale(1.3);
}

.app__cardproject_neumorfismo:active {
    /* color:var(--primary-color); */
    transform: scale(1);
}


@media screen and (max-width: 450px) {
  
    .app__cardproject {
        width: 280px;
        height: 320px;
    }

  }

  @media screen and (max-width: 360px) {
  
    .app__cardproject {
        width: 220px;
        height: 320px;
    }

    .app__cardproject_technologies {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items:flex-start;
        gap: 9px;
        max-height: 100px;
        margin-top: 0;
        /* margin-right: 5px;
        margin-top: 1px;
        margin-left: 5px;
        margin-bottom: 0; */
    }

    .app__cardproject_technologies h5 {
        font-family: var(--font-alt);
        color: var(--secondary-color);
        font-size: 0.8rem;
        margin-top: 0;
        margin-bottom: 0;
        height: 20px;
    }

    .app__cardproject h2 {
        font-family: var(--font-base);
        color: black;
        font-size: 1.5rem;
        /* margin: 0; */
    }
    
    .app__cardproject p {
        font-size: 0.8rem;
        font-family: var(--font-alt);
        font-weight: 500;
        letter-spacing: 1px;
        color: #404040;
        text-align: left;
    }
    
    .app__cardproject_info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0px;
        margin-right: 5px;
        margin-left: 5px;
        align-items: flex-start;
    }

    .iconscard {
        font-size: 2rem;
        color: var(--primary-color);
    }

    .iconscard_external_link {
        font-size: 1.5rem;
        color: #404040;
        cursor: pointer;
        /* box-shadow: 5px 5px 5px gray; */
    }
    
    .app__cardproject_neumorfismo{
        border: none;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        
    }

  }