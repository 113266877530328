.app__cardtime {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.app__cardtime_wrapper {
    display: flex;
    width: 100%;
}

.app__cardtime_headerinfo {
    display: flex;
    flex: 2;
    flex-direction: column;
}

.app__cardtime_img {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.app__cardtime_title {
    font-family: var(--font-base);
    margin-bottom: 2px;
}

.app__cardtime_subtitle {
    font-family: var(--font-alt);
    font-size: 0.85rem;
    color: #737474;
    margin-top: 2px;
    margin-bottom: 5px;
}

.app__cardtime_p {
    font-family: var(--font-alt);
    font-size: 0.85rem;
    text-align: justify;
}